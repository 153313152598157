import React from 'react';
import './SentList.scss';
import { useLocation } from 'react-router';
import Campaigns from './Lists/Campaigns/Campaigns';
import Purposes from './Lists/Purposes/Purposes';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppPaper, BirdzTitle } from '@applications-terrains/birdz-react-library';
import { Box, Tab, Tabs } from '@mui/material';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

enum TabIndex {
    CAMPAIGNS = 0,
    PURPOSES = 1
}

export const SentList = () => {
    const query = useQuery();
    const getTabQuery = () => {
        return query.get('tab');
    };
    const tabQuery = getTabQuery();
    const { search } = useLocation();
    const navigate = useNavigate();

    const [valueTab, setValueTab] = React.useState<null | number>(null);

    useEffect(() => {
        const newValueTab = TabIndex[tabQuery?.toUpperCase() as keyof typeof TabIndex];
        if (valueTab !== newValueTab && tabQuery) {
            setValueTab(TabIndex[tabQuery?.toUpperCase() as keyof typeof TabIndex]);
        }
    }, [search, tabQuery, valueTab]);

    const handleChangeTab = (event: any, newValueTab: number) => {
        if (valueTab === newValueTab) {
            return;
        } else {
            const params = new URLSearchParams();
            params.set('tab', TabIndex[newValueTab].toLowerCase());
            navigate({ pathname: '', search: params.toString() });
            setValueTab(newValueTab);
        }
    };

    return (
        <AppPaper>
            <Box
                sx={{ mb:3, borderBottom: '1px solid', borderColor: 'divider' }}>
                <BirdzTitle>Récapitulatif des envois</BirdzTitle>
            </Box>
            {valueTab !== null && (
                <Tabs
                    value={valueTab}
                    onChange={handleChangeTab}
                    sx={{ borderBottom: 1, borderColor: 'divider', mb:1 }}>
                    <Tab label="Liste des campagnes" />
                    <Tab label="Liste des intentions" />
                </Tabs>
            )}
            <>
                {<Campaigns display={valueTab === TabIndex.CAMPAIGNS} />}
                {<Purposes display={valueTab === TabIndex.PURPOSES} />}
            </>
        </AppPaper>
    );
};
export default SentList;
