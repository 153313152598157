import { BirdzTitle } from '@applications-terrains/birdz-react-library';
import PurposesList from '../Purposes/PurposesList';
import { useSearchCampaignsAndPurposesContext } from '../SearchCampaignsAndPurposesContext';
import { useEffect, useState } from 'react';
import { Box, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import { Campaign } from '../../../types';

const CampaignDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [campaign, setCampaign] = useState<Campaign | null>(null);
    
    const { setCampaignDetails, campaignDetails } = useSearchCampaignsAndPurposesContext();

    useEffect(() => {
        if (!campaignDetails && id)
            axios.get('/api/campaigns/' + id + '/').then((response) => {
                setCampaign(response.data);
            });
    }, [campaignDetails, id]);

    const details = campaignDetails || campaign;

    return (
        <Paper
            sx={{
                boxShadow: 'none',
                border: '1px solid',
                borderColor: 'divider',
                overflow: 'hidden'
            }}
            className="d-flex flex-column flex-grow-1">
            <Box sx={{ mx: 2, borderBottom: '1px solid',borderColor: 'divider' }} className="d-flex align-items-center">
                <Tooltip title={'retour liste'} placement="top">
                    <IconButton
                        id={`backToList`}
                        onClick={() => {
                            navigate(-1);
                            setCampaignDetails(null);
                        }}
                        sx={{ mr: 2, mt: 1 }}>
                        <ArrowBackIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <BirdzTitle className="d-flex align-items-center">
                    Détails de la campagne :{' '}
                    <Typography sx={{ ml: 1 }} fontSize={22}>
                        {details?.name}
                    </Typography>
                </BirdzTitle>
            </Box>
            <Box className="flex-grow-1" sx={{ overflow: 'auto', p:2 }}>
                <PurposesList customFilters={'&campaign=' + details?.id} />
            </Box>
        </Paper>
    );
};

export default CampaignDetails;
