import React from 'react';
import CampaignsList from '../Lists/Campaigns/CampaignsList';
import { AppPaper, BirdzTitle } from '@applications-terrains/birdz-react-library';
import { SearchCampaignsAndPurposesContextProvider } from '../Lists/SearchCampaignsAndPurposesContext';

export const ArchivedCampaigns = () => {
    return (
        <AppPaper>
            <SearchCampaignsAndPurposesContextProvider>
                <BirdzTitle>Campagnes archivées</BirdzTitle>
                <CampaignsList archived={true} />
            </SearchCampaignsAndPurposesContextProvider>
        </AppPaper>
    );
};
export default ArchivedCampaigns;
