import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar/Sidebar';
import dataService from './Common/Services/dataService';
import routes from '../routes';
import { Box } from '@mui/material';
import './DashboardLayout.scss';
import { authService } from '..';
import { Breadcrumbs } from '@applications-terrains/birdz-react-library';
import moment from 'moment';

const DashboardLayout = ({ component, ...props }: any) => {
    const { staticContext, ...rest } = props;
    const [isReady, setIsReady] = useState<boolean>(false);

    useEffect(() => {
        async function loadDataService() {
            await dataService.loadData();
        }

        async function getUserInfo() {
            await authService.getUserInfo();
            if (authService.isAuthenticated) {
                await loadDataService();
            }
            setIsReady(true);
        }

        if (!authService.initialized) {
            getUserInfo();
        } else {
            loadDataService();
            setIsReady(true);
        }
    }, []);

    return (
        <>
            {isReady && (
                <div className="wrapper">
                    <Sidebar {...rest} />
                    <div className="main-panel">
                        <Box className="navbar">
                            {authService.user?.avatar && (
                                <div
                                    className="avatar"
                                    style={{
                                        backgroundImage: 'url("' + authService.user.avatar + '"'
                                    }}
                                />
                            )}
                            {authService.user ? authService.user.name : ''}
                        </Box>

                        <Box
                            className="d-flex align-items-center"
                            sx={{ backgroundColor: '#e9ecef' }}>
                            {/* {(
                                <IconButton
                                    onClick={goBack}
                                    sx={{ ml: 1 }}
                                    size="small">
                                    <KeyboardBackspaceIcon />
                                </IconButton>
                            )} */}
                            <Breadcrumbs routes={routes} />
                            {authService.user?.previous_login && (
                                <Box sx={{ ml: 'auto', mr: 2, fontSize: '13px', fontWeight: 300 }}>
                                    Dernière connexion le{' '}
                                    {moment(authService.user.previous_login).format(
                                        'DD/MM/YYYY à HH:mm:ss'
                                    )}
                                </Box>
                            )}
                        </Box>
                        <div className="page-content">
                            <div className="inner">{component}</div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DashboardLayout;
