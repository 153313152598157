import { SearchFormValue } from '@applications-terrains/birdz-react-library';
import { Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { omit } from 'lodash';
import useReinitializeForm from '../../hooks/useReinitializeForm';

const DatePickerRange = ({
    setFiltersValues,
    filtersValues,
    dateAfterLabel,
    dateBeforeLabel
}: {
    setFiltersValues: Dispatch<SetStateAction<SearchFormValue>>;
    filtersValues: SearchFormValue;
    dateAfterLabel: string;
    dateBeforeLabel: string;
}) => {
    const reset = useReinitializeForm();
    const EmptyDatePicker = () => <DatePicker value={null} />;

    const onChangeOrAccept = (target: string, value: string | null) => {
        if (value === null) {
            setFiltersValues({
                filterValues: omit(filtersValues?.filterValues, [target]),
                formValue: omit(filtersValues?.formValue, [target])
            });
        } else {
            const newValue = new Date(moment(value).format('YYYY-MM-DD'));
            setFiltersValues({
                formValue: {
                    ...filtersValues.formValue,
                    [target]: newValue
                },
                filterValues: {
                    ...filtersValues.filterValues,
                    [target]: newValue
                }
            });
        }
    };

    return (
        <Grid container alignItems={'center'} sx={{ mb: 2 }} className="date-picker-range">
            <Grid container xs={6} alignItems={'center'} sx={{ pr: 1 }}>
                <Grid item xs={3}>
                    Entre le
                </Grid>
                <Grid item xs={9}>
                    {reset ? (
                        <EmptyDatePicker />
                    ) : (
                        <DatePicker
                            slotProps={{ field: { clearable: true } }}
                            value={filtersValues.filterValues[dateAfterLabel] || null}
                            onChange={(value) => {
                                onChangeOrAccept(dateAfterLabel, value);
                            }}
                            onAccept={(value) => {
                                onChangeOrAccept(dateAfterLabel, value);
                            }}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid container xs={6} alignItems={'center'} sx={{ pl: 1 }}>
                <Grid item xs={3}>
                    et le
                </Grid>
                <Grid item xs={9}>
                    {reset ? (
                        <EmptyDatePicker />
                    ) : (
                        <DatePicker
                            slotProps={{ field: { clearable: true } }}
                            value={filtersValues.filterValues[dateBeforeLabel] || null}
                            onChange={(value) => {
                                onChangeOrAccept(dateBeforeLabel, value);
                            }}
                            onAccept={(value) => {
                                onChangeOrAccept(dateBeforeLabel, value);
                            }}
                        />
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DatePickerRange;
