import React, { useEffect, useState } from 'react';
import { SearchField } from '@applications-terrains/birdz-react-library';
import CampaignsList from './CampaignsList';
import { getAvailableSearchFilters, searchFields } from '../search.config';
import { useTranslation } from 'react-i18next';
import './CampaignsList.scss';
import SearchFormWithDatePickerRange from '../../../Tools/SearchFormWithDatePickerRange';
import { Box } from '@mui/material';
import { useSearchCampaignsAndPurposesContext } from '../SearchCampaignsAndPurposesContext';

const Campaigns = ({display}:{display:boolean}) => {
    const [campaignsSearchFields, setCampaignsSearchFields] = useState<SearchField[]>([]);
    const { t } = useTranslation();

    const { setCampaignsFiltersString, setCampaignsValues , campaignsValues} =
        useSearchCampaignsAndPurposesContext();

    // 'campaign' property set by default in search.config.ts, but 'id' is necessary here (campaign is for searchPurposes)
    const formatFilterString = (str: string) => str.replace('&campaign=', '&id=');

    useEffect(() => {
        if (campaignsSearchFields.length === 0) {
            let campaignsSearchFields = [...searchFields].map((searchField) => {
                let name = searchField.name;
                if (name.startsWith('campaign__')) {
                    name = searchField.name.replace('campaign__', '');
                }
                return { ...searchField, name };
            });
            const availableSearchFilters = getAvailableSearchFilters(t);

            // Add specific purposes search fields
            const createdByFilterPosition = campaignsSearchFields
                .map((searchField) => searchField.name)
                .indexOf('created_by__in');
            campaignsSearchFields.splice(
                createdByFilterPosition + 1,
                0,
                availableSearchFilters['campaignState']
            );
            setCampaignsSearchFields(campaignsSearchFields);
        }
    }, [campaignsSearchFields, t]);

    return (
        <Box style={{display: display ? 'block' : 'none'}}>
            <Box
                sx={{
                    p: 2,
                    mb: 2,
                    borderBottom: 1,
                    borderColor: 'divider'
                }}>
                <SearchFormWithDatePickerRange
                    filtersValues={campaignsValues}
                    setFiltersValues={setCampaignsValues}
                    fields={campaignsSearchFields}
                    setFilters={(str) => {
                        setCampaignsFiltersString(formatFilterString(str));
                    }}
                />
            </Box>
            <CampaignsList />
        </Box>
    );
};

export default Campaigns;
