import useCountdown from '../../hooks/useCountdown';
import { Alert, Box, Snackbar } from '@mui/material';

const NotifWithCountdown = ({
    action,
    notifObj
}: {
    action: () => void;
    notifObj: { type: 'error' | 'success'; content: string };
}) => {
    const notifCountdown: number = useCountdown(action);

    return (
        <Snackbar
            open={true}
            onClose={() => action()}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={4000}>
            <Alert severity={notifObj.type} sx={{ width: '100%' }}>
                <>{notifObj.content}</>
                <div className="d-flex">
                    Redirection dans <Box sx={{ width: '20px', ml: 0.5 }}>{notifCountdown}</Box>
                </div>
            </Alert>
        </Snackbar>
    );
};

export default NotifWithCountdown;
