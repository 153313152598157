import {
    SearchField,
    SearchForm,
    SearchFormValue
} from '@applications-terrains/birdz-react-library';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { getFilterString } from '../../utils';
import useReinitializeForm from '../../hooks/useReinitializeForm';
import { omit } from 'lodash';
import DatePickerRange from './DatePickerRange';
import { useLocation, useNavigate } from 'react-router-dom';

type SearchFormWithDatePickerRangeProps = {
    setFilters: (value: string) => void;
    setFiltersValues?: (values: SearchFormValue) => void;
    filtersValues?: SearchFormValue;
    fields: SearchField[];
    dateAfterLabel?: string;
    dateBeforeLabel?: string;
    onSubmit?: (rawFiltersValues: SearchFormValue) => void;
};
const SearchFormWithDatePickerRange = ({
    setFilters,
    setFiltersValues,
    filtersValues,
    fields,
    dateAfterLabel = 'created_at_after',
    dateBeforeLabel = 'created_at_before',
    onSubmit
}: SearchFormWithDatePickerRangeProps) => {
    const { search } = useLocation();
    const navigate = useNavigate();

    const reset = useReinitializeForm();

    const [values, setValues] = useState<SearchFormValue>({
        formValue: {},
        filterValues: {}
    });

    //tmp State to store data until submit button is clicked to avoid unnecessary re-renders of the form
    const [tmpState, setTmpState] = useState<{ hasChanged: boolean; state: SearchFormValue }>({
        hasChanged: false,
        state: {
            formValue: {},
            filterValues: {}
        }
    });

    // setFiltersValues & filtersValues are optional props (external state),
    // so they are used primarily if specified, otherwise default is setValues & values (internal state)
    const setState = setFiltersValues ? setFiltersValues : setValues;
    const state = filtersValues ? filtersValues : values;

    useEffect(() => {
        const resetComponent = () => {
            const emptyObj = {
                formValue: {},
                filterValues: {}
            };
            setState(emptyObj);
            setTmpState({ hasChanged: false, state: emptyObj });
            setFilters('');
            onSubmit && onSubmit(emptyObj);
            search.includes('tab=purposes&campaign=') && navigate('/sent-list?tab=purposes');
        };
        if (reset) resetComponent();
    }, [reset, setState, onSubmit, setFilters, search, navigate]);

    return (
        <>
            <DatePickerRange
                dateAfterLabel={dateAfterLabel}
                dateBeforeLabel={dateBeforeLabel}
                setFiltersValues={(newValue) => {
                    setTmpState((tmpState) => ({
                        hasChanged: true,
                        state: {
                            formValue: {
                                ...omit(tmpState.state.formValue, [
                                    dateBeforeLabel,
                                    dateAfterLabel
                                ]),
                                ...(newValue as SearchFormValue).formValue
                            },
                            filterValues: {
                                ...omit(tmpState.state.filterValues, [
                                    dateBeforeLabel,
                                    dateAfterLabel
                                ]),
                                ...(newValue as SearchFormValue).filterValues
                            }
                        }
                    }));
                }}
                filtersValues={tmpState.hasChanged ? tmpState.state : state}
            />
            <SearchForm
                onSubmit={(_: any, rawFiltersValues: SearchFormValue) => {
                    const { created_at_after, created_at_before, ...filterValues } =
                        rawFiltersValues.filterValues;
                    const obj = {
                        ...filterValues,
                        [dateAfterLabel]: tmpState.state.filterValues[dateAfterLabel]
                            ? moment(tmpState.state.filterValues[dateAfterLabel]).format(
                                  'YYYY-MM-DD'
                              )
                            : state.filterValues[dateAfterLabel]
                              ? moment(state.filterValues[dateAfterLabel]).format('YYYY-MM-DD')
                              : null,
                        [dateBeforeLabel]: tmpState.state.filterValues[dateBeforeLabel]
                            ? moment(tmpState.state.filterValues[dateBeforeLabel]).format(
                                  'YYYY-MM-DD'
                              )
                            : state.filterValues[dateBeforeLabel]
                              ? moment(state.filterValues[dateBeforeLabel]).format('YYYY-MM-DD')
                              : null
                    };
                    const formattedObjToString = getFilterString(obj);
                    setFilters(formattedObjToString);
                    if (tmpState.hasChanged) {
                        setTmpState({ hasChanged: false, state: tmpState.state });
                        setState(tmpState.state);
                        onSubmit && onSubmit(tmpState.state);
                    }
                }}
                fields={fields}
                values={state}
                filtersChange={(_, rawFiltersValues) => {
                    const obj = {
                        hasChanged: true,
                        state: rawFiltersValues
                    };
                    if (dateAfterLabel in tmpState.state.formValue) {
                        obj.state.formValue[dateAfterLabel] =
                            tmpState.state.formValue[dateAfterLabel];
                        obj.state.filterValues[dateAfterLabel] =
                            tmpState.state.filterValues[dateAfterLabel];
                    }
                    if (dateBeforeLabel in tmpState.state.formValue) {
                        obj.state.formValue[dateBeforeLabel] =
                            tmpState.state.formValue[dateBeforeLabel];
                        obj.state.filterValues[dateBeforeLabel] =
                            tmpState.state.filterValues[dateBeforeLabel];
                    }
                    if (dateAfterLabel in state.formValue) {
                        obj.state.formValue[dateAfterLabel] = state.formValue[dateAfterLabel];
                        obj.state.filterValues[dateAfterLabel] = state.filterValues[dateAfterLabel];
                    }
                    if (dateBeforeLabel in state.formValue) {
                        obj.state.formValue[dateBeforeLabel] = state.formValue[dateBeforeLabel];
                        obj.state.filterValues[dateBeforeLabel] =
                            state.filterValues[dateBeforeLabel];
                    }
                    setTmpState(obj);
                }}
            />
        </>
    );
};

export default SearchFormWithDatePickerRange;
