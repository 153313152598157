import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import fr from 'date-fns/locale/fr';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
// import _ from 'lodash';
import axios from 'axios';
// import Loader from 'react-spinners/BarLoader';
import {
    Alert,
    Box,
    Button,
    Card,
    CircularProgress,
    Grid,
    Tab,
    Tabs,
    TextField
} from '@mui/material';
import { authService } from '../..';
import { ScheduleOptions } from '../Common/ScheduleOptions/ScheduleOptions';
import dataService from '../Common/Services/dataService';
import OptimisationTabPanelWrapper from './Tabs/OptimisationTabPanelWrapper';
import {
    CreatedInstruction,
    FiltersType,
    Instructions,
    OptimisationFormProps,
    TotalsType,
    WarningsInstructions
} from './types';
import { getDefaultValue } from './utils';
import NotifWithCountdown from '../Tools/NotifWithCountdown';
import { useNavigate } from 'react-router-dom';

registerLocale('fr', fr);
setDefaultLocale('fr');

export const OptimisationForm = ({ contracts }: OptimisationFormProps): any => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const [instructions, setInstructions] = useState<Instructions>({});

    const [createdInstructions, setCreatedInstructions] = useState<CreatedInstruction[]>();
    const [warningsInstructions, setWarningInstructions] = useState<WarningsInstructions>();

    const [valueTab, setValueTab] = React.useState(0);

    const [filters, setFilters] = useState<FiltersType>(getDefaultValue(contracts, ''));

    const handleChangeTab = (event: any, newValueTab: number) => {
        setValueTab(newValueTab);
    };

    const [totals, setTotals] = useState<TotalsType>(
        getDefaultValue(contracts, {
            hasChanged: false,
            value: null
        })
    );

    // get number of devices matching filters query
    useEffect(() => {
        Object.entries(totals).forEach(([key, vals]) => {
            if (vals.hasChanged) {
                // since requests are done by ListPage component and number of devices is displayed by ListPage but not exposed
                // (length of array is limited due to pagination),
                // we need to get the info reading html, and attribute it to corresponding contract

                // get div displayed by ListPage that contains the number of results
                const elements = document.getElementsByClassName(
                    'MuiTypography-root MuiTypography-body1'
                );

                const values: { [key: string]: number } = {};
                for (let item of elements) {
                    const itemValue = parseInt(item.innerHTML.split(' ')[0]);

                    // get the html parent of the div which 'id' is set to the matching contract,
                    // to make the association between the div inside ListPage and the matching contract
                    let parent = item.parentElement;
                    while (parent !== null) {
                        if (contracts.includes(parent.id)) {
                            values[parent.id] = itemValue;
                            break;
                        }
                        parent = parent.parentElement;
                    }
                }
                const target = values[key];
                if (vals.value !== target) {
                    setTotals({
                        ...totals,
                        [key]: {
                            value: target || 0,
                            hasChanged: false
                        }
                    });
                }
            }
        });
    }, [totals, valueTab, contracts]);

    useEffect(() => {
        if (contracts) {
            axios.all(
                contracts.map((contract: string) =>
                    axios
                        .get(`/api/optimisation/devices-stats/?contract_code=${contract}`)
                        .then(({ data }) =>
                            setInstructions((instructions) => ({
                                ...instructions,
                                [contract]: {
                                    ...data,
                                    selectedModules: [],
                                    name: `${new Date().toISOString().replaceAll('-', '').substring(0, 8)}-${contract}-${authService.user?.email.split('@')[0]}-optim`
                                }
                            }))
                        )
                )
            );
        }
    }, [contracts]);

    const saveLink = (values: any) => {
        setIsLoading(true);
        const allSavePromises: Promise<void>[] = [];
        for (let contract in instructions) {
            const instruction = instructions[contract];
            let payload: any = {
                contract,
                name: instruction.name,
                description: values?.description,
                devices: null,
                sending_schedule_hr: values?.sending_schedule_hr || null,
                scheduled_date_hr: values?.scheduled_date_hr || null,
                sending_schedule_lorawan: values?.sending_schedule_lorawan || null,
                scheduled_date_lorawan: values?.scheduled_date_lorawan || null
            };

            const queryFilters = filters[contract].replace(/^&/, '?');
            const url =
                '/api/optimisation/optimisations/' +
                queryFilters +
                `${queryFilters ? '&' : '?'}contract_code=${contract}`;
            allSavePromises.push(axios.post(url, payload));
        }

        Promise.allSettled(allSavePromises).then((responses) => {
            let created: CreatedInstruction[] = [];
            let warnings: WarningsInstructions = {};

            responses.forEach((response: any, index: number) => {
                const contract = Object.keys(instructions)[index];

                const data = response?.value?.data;
                if (data?.created && data?.created.length > 0) {
                    data?.created.forEach((createdInstruction: CreatedInstruction) => {
                        created.push(createdInstruction);
                    });
                }
                if (data?.warnings && data.warnings.length > 0) {
                    warnings[contract] = warnings[contract] || [];
                    data.warnings.forEach((warning: string) => {
                        warnings[contract].push(warning);
                    });
                }
            });

            setWarningInstructions(warnings);
            setCreatedInstructions(created);
            setIsLoading(false);
        });
    };

    const HrProtocolId = dataService.getData('protocols').find((protocol: any) => {
        return protocol.name === 'Homerider';
    }).id;
    const LrwProtocolId = dataService.getData('protocols').find((protocol: any) => {
        return protocol.name === 'LoRaWAN';
    }).id;

    return (
        <>
            <Formik
                initialValues={{} as any}
                enableReinitialize={true}
                onSubmit={(values) => {
                    saveLink(values);
                }}>
                {({ values, getFieldProps, setFieldValue, setSubmitting }: any) => (
                    <Form>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                Description
                            </Grid>
                            <Grid alignItems={'center'} item xs={8}>
                                <TextField
                                    id={'optimisationForm-description-input'}
                                    type={'textarea'}
                                    fullWidth
                                    multiline
                                    rows={3}
                                    {...getFieldProps('description')}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                Planification
                            </Grid>
                            <Grid alignItems={'center'} item xs={8}>
                                <Box fontWeight={500} fontSize={15}>
                                    LoRaWan
                                </Box>
                                <ScheduleOptions
                                    onChange={(value: any) => {
                                        setFieldValue('sending_schedule_lorawan', value?.type);
                                        setFieldValue(
                                            'scheduled_date_lorawan',
                                            value?.scheduledDate
                                        );
                                    }}
                                    value={{
                                        type: values?.sending_schedule_lorawan,
                                        scheduledDate: values?.scheduled_date_lorawan
                                    }}
                                    protocolId={LrwProtocolId}
                                    displayLabel={false}
                                />

                                <Box fontWeight={500} fontSize={15} sx={{ mt: 1 }}>
                                    Homerider
                                </Box>
                                <ScheduleOptions
                                    onChange={(value: any) => {
                                        setFieldValue('sending_schedule_hr', value?.type);
                                        setFieldValue('scheduled_date_hr', value?.scheduledDate);
                                    }}
                                    value={{
                                        type: values?.sending_schedule_hr,
                                        scheduledDate: values?.scheduled_date_hr
                                    }}
                                    protocolId={HrProtocolId}
                                    displayLabel={false}
                                />
                            </Grid>
                        </Grid>

                        {contracts?.length > 0 && (
                            <Card sx={{ px: 3, py: 2, my: 2 }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={valueTab} onChange={handleChangeTab}>
                                        {contracts.map((contract: string) => {
                                            const total = instructions[contract]?.affiliation;
                                            const totalWithFilters = totals[contract]?.value;
                                            const stats = `(${totalWithFilters !== null ? totalWithFilters : total}/${total || 0})`;
                                            return (
                                                <Tab
                                                    key={`tab-${contract}`}
                                                    label={
                                                        <div className="d-flex gap-50">
                                                            <div>{contract}</div>
                                                            <div>
                                                                {total ? (
                                                                    stats
                                                                ) : (
                                                                    <CircularProgress
                                                                        color="inherit"
                                                                        size={'1rem'}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    }
                                                />
                                            );
                                        })}
                                    </Tabs>
                                </Box>
                                <OptimisationTabPanelWrapper
                                    contracts={contracts}
                                    instructions={instructions}
                                    valueTab={valueTab}
                                    setFilters={setFilters}
                                    filters={filters}
                                    setTotals={setTotals}
                                />
                            </Card>
                        )}

                        {createdInstructions && createdInstructions.length > 0 && (
                            <>
                                <h3 className="mt-2">
                                    {createdInstructions.length} chantier
                                    {createdInstructions.length > 1 && 's'} créé
                                    {createdInstructions.length > 1 && 's'} :
                                </h3>
                                <Alert severity={'success'} className="mt-2">
                                    <ul>
                                        {createdInstructions.map(
                                            (createdInstruction: CreatedInstruction) => {
                                                return (
                                                    <li>
                                                        {createdInstruction.name} (nombre modules:{' '}
                                                        {createdInstruction.modules_count})
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </Alert>
                                <NotifWithCountdown
                                    action={() => navigate('/sendings/sent-list?tab=campaigns')}
                                    notifObj={{
                                        type: 'success',
                                        content: `${createdInstructions.length} chantier${createdInstructions.length > 1 ? 's' : ''} créé${createdInstructions.length > 1 ? 's' : ''} avec succès`
                                    }}
                                />
                            </>
                        )}
                        {warningsInstructions && Object.keys(warningsInstructions).length > 0 && (
                            <>
                                <h3 className="mt-2">Avertissements:</h3>
                                <Alert severity={'warning'} className="mt-2">
                                    <ul>
                                        {Object.keys(warningsInstructions).map(
                                            (contract: string) => {
                                                return (
                                                    <li>
                                                        Contrat {contract}:
                                                        <ul>
                                                            {warningsInstructions[contract].map(
                                                                (warning: string) => {
                                                                    return <li>{warning}</li>;
                                                                }
                                                            )}
                                                        </ul>
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </Alert>
                            </>
                        )}

                        <Box sx={{ textAlign: 'center', mt: 1 }}>
                            <Button
                                id={'optimisationForm-create-button'}
                                variant="contained"
                                type="submit"
                                disabled={isLoading}
                                onClick={() => {
                                    setTimeout(() => setSubmitting(true));
                                }}>
                                Créer le{contracts.length > 1 && 's'} chantier
                                {contracts.length > 1 && 's'}
                            </Button>
                            <Button
                                id={'optimisationForm-save-button'}
                                sx={{ ml: 2 }}
                                variant="outlined"
                                type="submit"
                                disabled={!values.operator}>
                                Enregistrer
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default OptimisationForm;
