import {
    BirdzDialog,
    BirdzNotif,
    useDialog,
    useNotif
} from '@applications-terrains/birdz-react-library';
import React, { useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import dataService from '../../../Common/Services/dataService';
import { Box, Button, Chip, IconButton } from '@mui/material';
import './CampaignsList.scss';
import { authService } from '../../../..';
import {
    Archive,
    Delete,
    Description,
    Language,
    Person,
    Ballot,
    CalendarToday,
    Schedule,
    SvgIconComponent
} from '@mui/icons-material';
import DisplayStatus from './DisplayStatus';
import { CampaignListItem, CampaignsListProps } from './types';
import ExtendableChip from './ExtendableChip';
import ListPageWrapper from '../../../Tools/ListPageWrapper';
import DisplayActions from './DisplayActions';
import { useSearchCampaignsAndPurposesContext } from '../SearchCampaignsAndPurposesContext';

const FONT_SIZE = '0.9rem';

const DisplayCampaign = ({ campaign, index }: { campaign: CampaignListItem; index: number }) => {
    const operator = dataService.getOperator(campaign.operator);
    return (
        <Box
            sx={{
                minHeight: '80px',
                maxWidth: '550px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'center'
            }}>
            <div className="d-flex">
                <ExtendableChip
                    label={campaign.name}
                    title={'name'}
                    icon={<Ballot color="primary" />}
                    style={{
                        position: 'relative',
                        border: 'none',
                        backgroundColor: index % 2 === 0 ? 'rgb(249, 249, 249)' : 'white'
                    }}
                    maxNbOfChar={50}
                />
                {operator?.name && (
                    <ExtendableChip
                        label={operator?.name}
                        title="Opérateur utilisé"
                        icon={<Language style={{ fontSize: '16px', color: '#253053' }} />}
                        maxNbOfChar={campaign.name.length > 40 ? 8 : 100}
                        style={{
                            marginLeft: '0.5rem',
                            color: '#253053',
                            border: '1px solid #253053',
                            backgroundColor: index % 2 === 0 ? 'rgb(249, 249, 249)' : 'white'
                        }}
                    />
                )}
            </div>

            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
                {campaign.template_name && (
                    <ExtendableChip
                        label={campaign.template_name}
                        title="Modèle utilisé"
                        icon={<Description style={{ fontSize: '16px', color: '#616161' }} />}
                        maxNbOfChar={50}
                    />
                )}
                <Chip
                    label={campaign.created_by__name}
                    title="Créateur de la campagne"
                    size="small"
                    icon={<Person />}
                    style={{ fontSize: FONT_SIZE, border: 'none' }}
                    sx={{ ml: 1 }}
                    variant="outlined"
                />
            </Box>
        </Box>
    );
};

export const CampaignsList = ({ archived = false }: CampaignsListProps) => {
    const [selectedCampaigns, setSelectedCampaigns] = useState<any[]>([]);
    const [data, setData] = useState<any[]>([]);
    const [visible, setVisible] = useState<boolean>(true); // used to force component refresh
    const { notif, notifOptions } = useNotif();
    const { confirmDialog, closeDialog, dialogOptions } = useDialog();
    const { campaignsFiltersString: filters } = useSearchCampaignsAndPurposesContext();

    const campaignActions = [
        {
            name: 'actions',
            render: (campaign: any) => {
                return <DisplayActions campaign={campaign} refreshComponent={refreshComponent} />;
            }
        }
    ];

    const deleteCampaigns = () => {
        confirmDialog({
            title: 'Suppression des campagnes sélectionnées ',
            content:
                'Êtes-vous sûrs de vouloir supprimer les ' +
                selectedCampaigns.length +
                ' campagnes sélectionnées?',
            onValidate: () => {
                const deletePromises: Promise<void>[] = [];
                selectedCampaigns.forEach((selectedCampaign) => {
                    deletePromises.push(
                        axios.delete('/api/campaigns/' + selectedCampaign.id + '/')
                    );
                });
                axios
                    .all(deletePromises)
                    .then(
                        () => {
                            notif({
                                type: 'success',
                                content: 'Les campagnes ont été supprimées avec succès'
                            });
                        },
                        () => {
                            notif({
                                type: 'error',
                                content:
                                    'Une erreur est survenue lors de la suppression des campagnes'
                            });
                        }
                    )
                    .finally(() => {
                        refreshComponent();
                        closeDialog();
                    });
            },
            onCancel: () => {
                closeDialog();
            }
        });
    };

    const archiveCampaigns = () => {
        confirmDialog({
            title: 'Archivage des campagnes sélectionnées ',
            content:
                'Êtes-vous sûrs de vouloir archiver les ' +
                selectedCampaigns.length +
                ' campagnes sélectionnées?',
            onValidate: () => {
                axios
                    .post('/api/campaigns/archive/', {
                        campaigns: selectedCampaigns.map(
                            (selectedCampaign: any) => selectedCampaign.id
                        )
                    })
                    .then(
                        () => {
                            notif({
                                type: 'success',
                                content: 'Les campagnes ont été archivées avec succès'
                            });
                            refreshComponent();
                        },
                        () => {
                            notif({
                                type: 'error',
                                content: "Une erreur est survenue lors de l'archivage des campagnes"
                            });
                        }
                    )
                    .finally(() => {
                        refreshComponent();
                        closeDialog();
                    });
            },
            oncancel: () => {
                closeDialog();
            }
        });
    };

    const refreshComponent = () => {
        setVisible(false);
        setTimeout(() => {
            setSelectedCampaigns([]);
            setVisible(true);
        });
    };

    const BatchButtons = () => {
        return (
            <Box textAlign={'right'}>
                <IconButton
                    onClick={() => {
                        archiveCampaigns();
                    }}>
                    <Button
                        variant="contained"
                        size="small"
                        disabled={selectedCampaigns.length === 0}>
                        <Archive fontSize="small" sx={{ mr: 1 }} /> Archiver (
                        {selectedCampaigns.length})
                    </Button>
                </IconButton>

                {authService.canAccess('DELETE_CAMPAIGN') && (
                    <IconButton
                        onClick={() => {
                            deleteCampaigns();
                        }}>
                        <Button
                            variant="contained"
                            size="small"
                            disabled={selectedCampaigns.length === 0}>
                            <Delete fontSize="small" sx={{ mr: 1 }} /> Supprimer (
                            {selectedCampaigns.length})
                        </Button>
                    </IconButton>
                )}
            </Box>
        );
    };

    return (
        <Box className="campaignsList">
            {visible && (
                <>
                    {archived === false && <BatchButtons />}

                    <ListPageWrapper
                        onLoadItems={(data: any) => setData(data)}
                        endpoint={'/api/campaigns/'}
                        fields={[
                            {
                                name: 'custom',
                                label: 'Campagne',
                                // options: { width: '100px' },
                                transform: (_row: any, campaign: any) => {
                                    const index = data?.findIndex((el) => el.id === campaign.id);
                                    return <DisplayCampaign campaign={campaign} index={index} />;
                                }
                            },
                            {
                                name: 'id',
                                label: 'Id',
                                className: 'text-center',
                                options: { width: '50px' }
                            },
                            {
                                name: 'created_at',
                                label: 'Date',
                                // className: 'text-center',
                                options: { width: '50px' },
                                transform: (value: any) => {
                                    let date = value && moment.unix(value).format('DD/MM/YYYY');
                                    let time = value && moment.unix(value).format('HH:mm:ss');
                                    const Item = ({
                                        text,
                                        Icon
                                    }: {
                                        text: string;
                                        Icon: SvgIconComponent;
                                    }) => (
                                        <div className="d-flex align-items-center">
                                            {
                                                <Icon
                                                    color="primary"
                                                    style={{ fontSize: '16px', color: '#616161' }}
                                                    sx={{ mr: 1 }}
                                                />
                                            }{' '}
                                            {text}
                                        </div>
                                    );
                                    return date && time ? (
                                        <div
                                            className="d-flex flex-column"
                                            style={{ fontSize: FONT_SIZE, gap: 1 }}>
                                            <Item text={date} Icon={CalendarToday} />
                                            <Item text={time} Icon={Schedule} />
                                        </div>
                                    ) : (
                                        ''
                                    );
                                },
                                orderable: true
                            },
                            {
                                name: 'state',
                                label: 'Statut',
                                orderable: false,
                                transform: (_, campaign: CampaignListItem) => {
                                    return <DisplayStatus campaign={campaign} />;
                                },
                                options: {
                                    width: '180px'
                                }
                            },
                            {
                                name: 'total',
                                label: 'Total intentions',
                                orderable: false,
                                className: 'text-center',
                                options: { width: '50px' },
                                transform: (_, campaign: CampaignListItem) => {
                                    return campaign.purposes_count || '-';
                                }
                            }
                        ]}
                        actions={archived === false ? campaignActions : []}
                        filters={archived === true ? '&archived=true' + filters : filters}
                        onSelectItems={
                            archived === false
                                ? (items: any) => setSelectedCampaigns(items)
                                : undefined
                        }
                        defaultOrder={['-created_at']}
                        websocketCallback={(results: any, websocketData: any) => {
                            let newResults: any = [...results];
                            if (websocketData.type === 'create_campaign_purposes') {
                                newResults.forEach((result: any, idx: number) => {
                                    if (
                                        websocketData.campaign_id &&
                                        result.id === websocketData.campaign_id
                                    ) {
                                        newResults[idx].purposes_count = websocketData.count;
                                    }
                                });
                            }

                            return newResults;
                        }}
                    />
                </>
            )}
            <BirdzDialog options={dialogOptions} />
            <BirdzNotif options={notifOptions} />
        </Box>
    );
};

export default CampaignsList;
